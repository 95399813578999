import React from 'react'

const Chat = ({className}) => (
  // <Container className={className}>
  //     <H2>Don’t see your question or answer here? Contact us</H2>
  //     {breakpoints.md ? <Button src={chat} /> : <Button src={chatMobile} />}
  // </Container>
  <></>
)

export default Chat
